import { FC, useCallback, useMemo } from 'react';
import { Divider, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import {
  SearchResult,
  Table,
  TableCaption,
  TableColumns,
  tableRowClickEventWrapper,
  useTableRowHighlight,
} from '@fleet/shared';
import { useTable, usePagination, useRowSelect, Row } from 'react-table';
import { useRowActive } from '@fleet/shared/hooks/useRowActive';
import { useHistory, useParams } from 'react-router-dom';
import { Stop } from 'dto/stop';
import { useRowSelectCheckbox } from '@fleet/shared/hooks';
import {
  stopsFilterSelector,
  stopsSelector,
} from 'features/stop/stopSelectors';
import { StopsSearchForm } from 'routes/Stops/StopsSearchForm';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransTableHead } from 'i18n/trans/table';
import { deleteStops, getStopsList } from 'features/stop/stopActions';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { stopListLoadingSelector } from 'features/loading/loadingSelectors';

interface StopsTableProps {}

export const StopsTable: FC<StopsTableProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector(stopListLoadingSelector);
  const stops = useSelector(stopsSelector);
  const filter = useSelector(stopsFilterSelector);
  const { id } = useParams<{ action: 'create' | 'edit'; id?: string }>();

  const getPage = useCallback(
    (pageSize: number) => {
      if (stops) {
        const { limit = pageSize, offset } = stops;
        return offset / limit;
      }
      return 0;
    },
    [stops]
  );

  const link = useCallback(
    (row: Row<Stop>) => `/stops/edit/${row.original.id}`,
    []
  );

  const data = useMemo(() => stops?.items ?? [], [stops]);

  const columns = useMemo<TableColumns<Stop>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="stopName" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      {
        id: 'address',
        accessor: (row) => row.countryName,
        Header: <TransTableHead i18nKey="country" />,
      },
      {
        id: 'county',
        accessor: (row) => row.countyName,
        Header: <TransTableHead i18nKey="county" />,
      },
      {
        id: 'city',
        accessor: (row) => row.cityName,
        Header: <TransTableHead i18nKey="cityParish" />,
      },
      {
        id: 'street',
        accessor: (row) => row.streetAddress,
        Header: <TransTableHead i18nKey="streetHouseNumber" />,
      },
    ],
    [link]
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getStopsList({ ...filter, ...paginationParams })).unwrap(),
    [dispatch, filter]
  );
  const initialState = useMemo(
    () => ({ pageSize: 10, activeRowId: id, filter }),
    [id, filter]
  );
  const getRowId = useCallback((row: Stop) => `${row.id}`, []);
  const table = useTable(
    {
      data,
      columns,
      initialState,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: stops?.totalCount,
      getRowId,
    },
    usePagination,
    useRowActive,
    useRowSelect,
    useRowSelectCheckbox
  );
  const {
    state: { selectedRowIds },
  } = table;

  const selectedStopIds = useMemo(
    () => Object.keys(selectedRowIds).map((id) => Number(id)),
    [selectedRowIds]
  );

  const removeSelectedStops = useCallback(async () => {
    await dispatch(deleteStops(selectedStopIds));
    dispatch(getStopsList());
  }, [dispatch, selectedStopIds]);

  useTableRowHighlight(id, table);

  return (
    <>
      <StopsSearchForm />
      <Divider />
      <SearchResult results={data.length} loading={loading}>
        <Table
          caption={
            <TableCaption>
              <Button
                // TODO, wait for BE implementation
                // https://youtrack.tsolutions.co/issue/BR-44922#focus=Comments-58-187964.0-0, point 11
                // Can't remove created stop, API returns 405 because endpoint does not exist. Please remove this button from UI - not OK, still reproducible
                startIcon={<Icon name="trash" />}
                sx={{ ml: 'auto', px: 1, py: 0 }}
                onClick={removeSelectedStops}
                color="error"
                // disabled={!Boolean(selectedStopIds.length)}
                disabled
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
            </TableCaption>
          }
          table={table}
          getRowProps={(_, { row }) => ({
            sx: { cursor: 'pointer' },
            onClick: () => history.push(link(row)),
          })}
        />
      </SearchResult>
    </>
  );
};
