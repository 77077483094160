import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  Icon,
  Loadable,
  Modal,
  Table,
  TableColumns,
  useForm,
} from '@fleet/shared';
import { Button, IconButton, Stack } from '@mui/material';
import {
  useFormTable,
  useFormTableControls,
  useRowEditActions,
} from '@fleet/shared/hooks';
import { TransTableHead } from 'i18n/trans/table';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import {
  createUpdateStopPlatformLocalization,
  deleteStopPlatformLocalization,
  getStopPlatformLocalizations,
  getStopPlatforms,
} from 'features/stop/stopActions';
import { stopPlatformLocalizationsLoadingSelector } from 'features/loading/loadingSelectors';
import type { Stop, StopPlatform, StopPlatformLocalization } from 'dto/stop';
import { ClassificationGroup } from 'dto/classification';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { IdType, useRowSelect } from 'react-table';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';

interface StopFormPlatformLocalizationsProps {
  stopId: Stop['guid'];
  platformId: StopPlatform['id'];
  localizations: Array<StopPlatformLocalization>;
  open: boolean;
  rowId: string;
  toggleRowExpanded: (id: Array<IdType<StopPlatform>>, value?: boolean) => void;
}

export const StopFormPlatformLocalizations: FC<StopFormPlatformLocalizationsProps> =
  ({
    stopId,
    platformId,
    localizations: initialLocalizations = [],
    open,
    rowId,
    toggleRowExpanded,
  }) => {
    const languages = useClassificationOptions(ClassificationGroup.CULTURE);
    const dispatch = useDispatch();

    const loading = useSelector(stopPlatformLocalizationsLoadingSelector);
    const [localizations, setLocalizations] =
      useState<Array<StopPlatformLocalization>>(initialLocalizations);
    useEffect(() => {
      if (open) dispatch(getStopPlatformLocalizations(platformId));
    }, [dispatch, open, platformId]);

    const { form } = useForm<{ rows: Array<StopPlatformLocalization> }>({
      initialValues: {
        rows: localizations,
      },
    });

    const columns = useMemo<TableColumns<StopPlatformLocalization>>(
      () => [
        {
          id: 'culture.id',
          accessor: ({ culture }) => culture?.id,
          type: 'select',
          editableProps: {
            options: languages,
          },
          Header: <TransTableHead i18nKey="language" />,
          width: '40%',
        },
        {
          accessor: 'name',
          Header: <TransTableHead i18nKey="name" />,
          editableProps: {
            required: false,
          },
        },
        {
          accessor: 'streetAddress',
          Header: <TransTableHead i18nKey="streetAddress" />,
          editableProps: {
            required: false,
          },
        },
      ],
      [languages]
    );

    const alert = useAlert();
    const handleUpdateRows = useCallback(
      async (value) => {
        await dispatch(
          createUpdateStopPlatformLocalization({
            platformId: platformId,
            payload: { ...value, culture: value.culture.id },
          })
        ).unwrap();
        const localizations = await dispatch(
          getStopPlatformLocalizations(platformId)
        ).unwrap();
        alert.success(
          <TransAlert
            i18nKey={
              value.id
                ? 'stopPlatformLocalizationUpdated'
                : 'stopPlatformLocalizationCreated'
            }
          />
        );
        setLocalizations(localizations);
      },
      [alert, dispatch, platformId]
    );

    const handleDeleteRow = useCallback(
      async (e: MouseEvent<HTMLButtonElement>) => {
        await dispatch(
          deleteStopPlatformLocalization({
            platformId,
            localizationId: e.currentTarget.id,
          })
        ).unwrap();
        const localizations = await dispatch(
          getStopPlatformLocalizations(platformId)
        ).unwrap();
        alert.success(<TransAlert i18nKey="stopPlatformLocalizationDeleted" />);
        setLocalizations(localizations);
      },
      [alert, dispatch, platformId]
    );

    const table = useFormTable<StopPlatformLocalization>(
      {
        data: localizations,
        columns,
        form,
        onRowUpdate: handleUpdateRows,
      },
      useRowSelect,
      useRowEditActions,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          ...columns,
          {
            accessor: 'id',
            Cell: ({ row }) => (
              <IconButton
                id={row.original.id}
                color="error"
                onClick={handleDeleteRow}
              >
                <Icon name="delete" />
              </IconButton>
            ),
            width: 40,
          },
        ]);
      }
    );

    const { addRow } = useFormTableControls({
      table,
      form,
    });

    const handleClose = useCallback(() => {
      toggleRowExpanded([rowId], false);
      dispatch(getStopPlatforms(stopId));
    }, [dispatch, rowId, stopId, toggleRowExpanded]);

    return (
      <Modal
        title="Localizations"
        actionButton={<></>}
        open={open}
        maxWidth="lg"
        fullWidth
        onClose={handleClose}
      >
        <Loadable loading={loading}>
          <FormProvider form={form}>
            <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1 }}>
              <Button
                variant="text"
                onClick={addRow}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
            <Table
              table={table}
              getTableProps={() => ({
                sx: { tableLayout: 'fixed' },
              })}
            />
          </FormProvider>
        </Loadable>
      </Modal>
    );
  };
