import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransModal = createTrans({
  attributeDeletionDescription: (
    <Trans
      i18nKey="modal.title.attributeDeletionDescription"
      defaults="Are you sure you want to delete selected stop attribute? You can't undo this"
    />
  ),
  attributeDeletionTitle: (
    <Trans
      i18nKey="modal.title.attributeDeletionTitle"
      defaults="Delete stop attribute"
    />
  ),
  attributeLocalizationDeletionDescription: (
    <Trans
      i18nKey="modal.title.attributeLocalizationDeletionDescription"
      defaults="Are you sure you want to delete selected stop attribute localization(s)? You can't undo this"
    />
  ),
  attributeLocalizationDeletionTitle: (
    <Trans
      i18nKey="modal.title.attributeLocalizationDeletionTitle"
      defaults="Delete stop attribute localization(s)"
    />
  ),
});
